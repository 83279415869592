import type { CustomCssVarsFn } from '@wix/yoshi-flow-editor';

import { ProfileAlignment } from '../../../types';

export const getProfileAlignmentCustomCssVars: CustomCssVarsFn = ({
  styleParams,
  isRTL,
}) => {
  const { numbers } = styleParams;
  const profileAlignment = numbers.profileAlignment as ProfileAlignment;
  const isProfileAlignRight =
    profileAlignment === ProfileAlignment.Right && !isRTL;
  const isProfileAlignCenter = profileAlignment === ProfileAlignment.Center;

  const coverButtonsBoxMarginLeft = isProfileAlignRight ? '-14px' : 'initial';

  const coverButtonsBoxPosition = isProfileAlignRight ? 'absolute' : 'initial';
  const coverButtonsBoxTop = isProfileAlignRight ? '21px' : 'initial';
  const coverButtonsBoxInsetInlineEnd = isProfileAlignRight
    ? '24px'
    : 'initial';
  const coverButtonsBoxMarginTop = isProfileAlignRight ? 'initial' : '38px';

  const repositionModeButtonsFlexDirection = isProfileAlignRight
    ? 'row-reverse'
    : 'row';
  const repositionModeButtonsMarginInlineStart = isProfileAlignRight
    ? '0'
    : '34px';
  const repositionModeButtonsMarginInlineEnd = isProfileAlignRight
    ? '34px'
    : 'initial';

  const coverPhotoContainerInsetInlineStart = isProfileAlignRight
    ? 'auto !important'
    : '24px';
  const coverPhotoContainerInsetInlineEnd = isProfileAlignRight
    ? '24px'
    : isProfileAlignCenter
    ? 'auto !important'
    : 'initial';

  const contentLayoutPosition = isProfileAlignCenter ? 'inherit' : 'relative';
  const contentLayoutFlexDirection = isProfileAlignCenter
    ? 'column'
    : isProfileAlignRight
    ? 'row-reverse'
    : 'row';
  const contentLayoutAlignItems = isProfileAlignCenter ? 'center' : 'initial';
  const contentLayoutPaddingInlineStart = isProfileAlignCenter ? '0' : '30px';
  const contentLayoutPaddingInlineEnd = isProfileAlignCenter ? '0' : '16px';

  const contentAlignItems = isProfileAlignCenter ? 'center' : 'flex-end';
  const contentMarginBottom = isProfileAlignCenter ? '0' : '30px';
  const contentMarginInlineEnd = isProfileAlignCenter
    ? '0'
    : isProfileAlignRight
    ? '24px'
    : 'initial';
  const contentMarginInlineStart =
    isProfileAlignCenter || isProfileAlignRight ? '0' : '24px';
  const contentJustifyContent = isProfileAlignCenter
    ? 'center'
    : 'space-between';

  const contentWidth = isProfileAlignCenter ? '100%' : 'initial';
  const contentFlexDirection = isProfileAlignRight ? 'row-reverse' : 'row';

  const detailsAndPhotoFlexDirection = isProfileAlignCenter
    ? 'column'
    : isProfileAlignRight
    ? 'row-reverse'
    : 'initial';
  const detailsAndPhotoAlignItems = isProfileAlignCenter ? 'center' : 'initial';

  const memberDetailsPaddingInlineStart = isProfileAlignRight
    ? '30px'
    : 'initial';
  const memberDetailsPaddingInlineEnd =
    isProfileAlignCenter || isProfileAlignRight ? '0 !important' : 'initial';
  const memberDetailsMarginTop = isProfileAlignCenter ? '10px' : 'initial';
  const memberDetailsAlignItems = isProfileAlignCenter ? 'center' : 'initial';
  const memberDetailsMaxWidth = isProfileAlignCenter ? '800px' : '500px';
  const memberDetailsMarginBottom = isProfileAlignCenter ? '0' : 'initial';

  const profilePhotoMarginRight =
    isProfileAlignCenter || isProfileAlignRight ? '0' : 'initial';
  const profilePhotoMarginLeft = isProfileAlignRight ? '24px' : 'initial';

  const badgeListContainerJustifyContent = isProfileAlignRight
    ? 'flex-end'
    : 'initial';

  const badgeListJustifyContent = isProfileAlignCenter
    ? 'center'
    : isProfileAlignRight
    ? 'flex-start'
    : 'end';

  const numbersBoxPosition = isProfileAlignCenter ? 'relative' : 'initial';
  const numbersBoxJustifyContent = isProfileAlignCenter
    ? 'center'
    : isProfileAlignRight
    ? 'flex-end'
    : 'flex-start';

  const nameContainerMaxWidth = isProfileAlignCenter
    ? 'fit-content'
    : 'initial';
  const nameContainerWidth = isProfileAlignCenter ? 'max-content' : 'initial';
  const nameContainerJustifyContent = isProfileAlignCenter
    ? 'center'
    : isProfileAlignRight
    ? 'flex-end'
    : 'flex-start';
  const nameBoxTextMaxWidth = isProfileAlignCenter ? '600px' : '470px';

  const titleContainerTextAlign = isProfileAlignCenter
    ? 'center'
    : isProfileAlignRight
    ? 'end'
    : 'initial';
  const titleContainerDisplay = isProfileAlignRight ? 'flex' : 'initial';
  const titleContainerFlexDirection = isProfileAlignCenter
    ? 'row-reverse'
    : 'initial';
  const titleMaxWidth = isProfileAlignCenter ? '800px' : '470px';

  const buttonsBoxPosition = isProfileAlignCenter ? 'absolute' : 'initial';
  const buttonsBoxTop = isProfileAlignCenter ? '21px' : 'initial';
  const buttonsBoxInsetInlineEnd = isProfileAlignCenter ? '24px' : 'initial';

  const moreActionsMarginLeft = isProfileAlignRight ? '-14px' : 'initial';
  const moreActionsMarginRight = !isProfileAlignRight ? '-14px' : 'initial';

  return {
    coverButtonsBoxMarginLeft,
    coverButtonsBoxPosition,
    coverButtonsBoxTop,
    coverButtonsBoxInsetInlineEnd,
    coverButtonsBoxMarginTop,
    repositionModeButtonsFlexDirection,
    repositionModeButtonsMarginInlineStart,
    repositionModeButtonsMarginInlineEnd,
    coverPhotoContainerInsetInlineStart,
    coverPhotoContainerInsetInlineEnd,
    contentLayoutPosition,
    contentLayoutFlexDirection,
    contentLayoutAlignItems,
    contentLayoutPaddingInlineStart,
    contentLayoutPaddingInlineEnd,
    contentAlignItems,
    contentMarginBottom,
    contentMarginInlineEnd,
    contentMarginInlineStart,
    contentJustifyContent,
    contentWidth,
    contentFlexDirection,
    detailsAndPhotoFlexDirection,
    detailsAndPhotoAlignItems,
    memberDetailsPaddingInlineStart,
    memberDetailsPaddingInlineEnd,
    memberDetailsMarginTop,
    memberDetailsAlignItems,
    memberDetailsMaxWidth,
    memberDetailsMarginBottom,
    profilePhotoMarginRight,
    profilePhotoMarginLeft,
    badgeListContainerJustifyContent,
    badgeListJustifyContent,
    numbersBoxPosition,
    numbersBoxJustifyContent,
    nameContainerMaxWidth,
    nameContainerWidth,
    nameContainerJustifyContent,
    nameBoxTextMaxWidth,
    titleContainerTextAlign,
    titleContainerDisplay,
    titleContainerFlexDirection,
    titleMaxWidth,
    buttonsBoxPosition,
    buttonsBoxTop,
    buttonsBoxInsetInlineEnd,
    moreActionsMarginLeft,
    moreActionsMarginRight,
  };
};
